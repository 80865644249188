<template>
  <v-date-picker
    v-model="internalValue"
    v-bind="pickerOptions"
    full-width
    :color="colorHex"
    :header-color="headerColorHex"
    :allowed-dates="allowedDates"
    data-test="date-picker"
  >
    <section class="v-date-picker__buttons-footer">
      <ev-button
        data-test="date-picker-button-cancel"
        color="blue"
        variant="tertiary"
        @click="$emit('cancel')"
      >
        {{ $t(`${langPath}.cancelLabel`) }}
      </ev-button>

      <ev-button
        data-test="date-picker-button-ok"
        variant="tertiary"
        color="blue"
        @click="$emit('input', internalValue)"
      >
        {{ $t(`${langPath}.okLabel`) }}
      </ev-button>
    </section>
  </v-date-picker>
</template>

<script>
import dateFormatter from '@/helpers/date-formatter.js'
import dateMethods from '@/helpers/date-methods.js'
import { styleTokens } from '@revelotech/everest'
import PICKER_OPTS from '@/configs/date-picker'
import EvButton from '@revelotech/everestV2/EvButton'

const COLOR_OPTIONS = {
  primary: styleTokens.red,
  secondary: styleTokens.gray100
}

export default {
  name: 'DatePicker',
  components: {
    EvButton
  },
  props: {
    value: {
      type: String,
      default: dateFormatter.todayISOStr()
    },
    color: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary'].includes(value)
      }
    },
    headerColor: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary'].includes(value)
      }
    }
  },
  data () {
    return {
      langPath: __langpath,
      internalValue: this.value
    }
  },
  computed: {
    colorHex () {
      return COLOR_OPTIONS[this.color]
    },
    headerColorHex () {
      return COLOR_OPTIONS[this.headerColor]
    },
    pickerOptions () { return PICKER_OPTS.default }
  },
  methods: {
    allowedDates (date) { return dateMethods.isWeekDay(date) }
  }
}
</script>

<style lang="scss">
.v-date-picker {
  width: 100%;

  &__buttons-footer {
    border: none;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>
