<template>
  <section>
    <app-grid-col :md="12">
      <p class="applicant-proposal-choose__header">
        {{ $t(`${langPath}.proposalHeader`) }}
      </p>
      <p v-if="marketplaceSource" class="applicant-proposal-choose__sub-header">
        {{ $t(`${langPath}.proposalSubHeader`) }}
      </p>
      <section class="applicant-proposal-choose__input-group">
        <app-radio-input
          id="applicant-proposal-choose-option-formalize"
          v-model="option"
          name="applicant-proposal-choose-option-formalize"
          value="formalize"
          data-test="applicant-option-formalize"
          class="applicant-proposal-choose__input-option"
        />
        <label
          for="applicant-proposal-choose-option-formalize"
          class="applicant-proposal-choose__input-label"
        >
          {{ $t(`${langPath}.optionFormalize`) }}
        </label>
      </section>
      <section
        v-if="marketplaceSource"
        class="applicant-proposal-choose__input-group"
      >
        <app-radio-input
          id="applicant-proposal-choose-option-proposal"
          v-model="option"
          name="applicant-proposal-choose-option-proposal"
          value="proposal"
          data-test="applicant-option-proposal"
          class="applicant-proposal-choose__input-option"
        />
        <label
          for="applicant-proposal-choose-option-proposal"
          class="applicant-proposal-choose__input-label"
        >
          {{ $t(`${langPath}.optionProposal`) }}
        </label>
      </section>
      <section class="applicant-proposal-choose__form">
        <component
          :is="proposalForm"
          v-if="proposalForm"
          :applicant="applicant"
          :candidate-name="candidateName"
          @success="$emit('success', $event)"
        />
      </section>
    </app-grid-col>
  </section>
</template>

<script>
import ApplicantProposalForm from './ApplicantProposalForm'
import ApplicantProposalFormalize from './ApplicantProposalFormalize'

export default {
  name: 'ApplicantProposalChoose',
  props: {
    candidateName: {
      type: String,
      required: false,
      default: ''
    },
    candidateSource: {
      type: String,
      required: false,
      default: ''
    },
    applicant: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      option: ''
    }
  },
  computed: {
    proposalForm () {
      if (this.option === 'proposal') {
        return ApplicantProposalForm
      } else if (this.option === 'formalize') {
        return ApplicantProposalFormalize
      }
      return null
    },
    marketplaceSource () {
      return this.candidateSource === 'marketplace'
    }
  },
  created () {
    if (this.$route.query.selectedOption) {
      this.option = this.$route.query.selectedOption
    }
  }
}
</script>

<style lang="scss">
.applicant-proposal-choose {
  &__sub-header {
    margin-top: 8*$base;
  }

  &__input-group {
    align-items: flex-start;
    display: flex;
    margin-top: 2*$base;
  }

  &__input-label {
    margin-top: 4*$base;
  }

  &__input-option {
    margin-right: $base;
  }

  &__form {
    margin-top: 6*$base;
  }
}
</style>
