<template>
  <v-dialog
    v-model="modal"
    data-app
    persistent
    width="290px"
    @keydown.esc="modal = false"
  >
    <template #activator="{ on }">
      <section
        class="date-picker-dialog__input-wrapper"
        :class="inputClass"
      >
        <input
          data-test="date-picker-input"
          class="date-picker-dialog__input"
          :placeholder="placeholder"
          autocomplete="off"
          :value="formattedDate"
          name="datePicker"
          v-on="on"
          @keyup.tab="modal = true"
        >
        <app-assistive-text :assistive-text="error" variant="error" />
      </section>
    </template>

    <date-picker
      v-if="modal"
      @cancel="modal = false"
      @input="handleInput"
    />
  </v-dialog>
</template>

<script>
import DatePicker from './DatePicker'
import { validateField } from '@revelotech/everest'

export default {
  name: 'DatePickerDialog',
  components: { DatePicker },
  mixins: [validateField],
  props: {
    value: {
      type: String,
      default: ''
    },
    pickerOptions: {
      type: Object,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      modal: false
    }
  },
  computed: {
    formattedDate () {
      if (this.value) {
        return this.$moment(this.value).format('LL')
      }
      return ''
    }
  },
  methods: {
    handleInput (date) {
      this.$emit('input', date)
      this.modal = false
    }
  }
}
</script>

<style lang="scss">
.v-dialog__container {
  height: 0;
  width: 0;
}

.date-picker-dialog {
  &__input {
    @extend %input-text;

    @include margin(top, 2);

    height: 12*$base;
  }
}
</style>
