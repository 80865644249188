<template>
  <app-grid class="applicant-proposal-form">
    <app-grid-col
      :md="12"
    >
      <p class="applicant-proposal-form__description">
        {{ $t(`${langPath}.proposalDescription`, { name: candidateName }) }}
      </p>
    </app-grid-col>
    <app-grid-col
      class="applicant-proposal-form__field"
      :md="6"
    >
      <date-picker-dialog
        v-model="proposalForm.jobStartDate"
        data-test="proposal-form__start-date"
        class="proposal-form__body__start-date-input"
        name="job-start-date"
        :placeholder="$t(`${langPath}.startDate`)"
      />
    </app-grid-col>
    <app-grid-col
      class="applicant-proposal-form__field"
      :md="6"
    >
      <app-text-input
        v-model="proposalForm.jobSalary"
        v-validate="'money_required|money_max:100000'"
        data-test="proposal-form__salary"
        name="salary"
        money-mask
        :data-vv-as="$t(`${langPath}.salary`)"
        data-vv-validate-on="blur"
      />
    </app-grid-col>
    <app-grid-col
      class="applicant-proposal-form__field"
      :md="12"
    >
      <applicant-proposal-benefits
        v-model="proposalForm.benefits"
        data-test="proposal-form__message"
        name="proposalBenefits"
        :placeholder="$t(`${langPath}.benefits`)"
        :data-vv-as="$t(`${langPath}.benefits`)"
      />
      <span class="applicant-proposal-form__field__assistive-text">
        {{ $t(`${langPath}.benefitsAssistiveText`) }}
      </span>
    </app-grid-col>
    <app-grid-col
      class="applicant-proposal-form__field"
      :md="12"
    >
      <app-text-area
        v-model="proposalForm.message"
        v-validate="'required'"
        required
        data-test="proposal-form__message"
        name="proposalMessage"
        :placeholder="$t(`${langPath}.proposalMessage`)"
        :data-vv-as="$t(`${langPath}.proposalMessage`)"
        data-vv-validate-on="blur"
      />
      <span class="applicant-proposal-form__field__assistive-text">
        {{ $t(`${langPath}.proposalMessageDescription`) }}
      </span>
    </app-grid-col>
    <app-grid-col
      class="applicant-proposal-form__field"
      :md="12"
    >
      <ev-button
        data-ga-id="confirmed_proposal_submission"
        data-test="proposal-form__send-button"
        @click="handleSubmit"
      >
        {{ $t(`${langPath}.send`) }}
      </ev-button>
    </app-grid-col>
  </app-grid>
</template>

<script>
import ApplicantProposalBenefits from './ApplicantProposalBenefits'
import DatePickerDialog from '@/components/DatePickerDialog'
import moneyHelper from '@/helpers/money'
import { TheNotificationCardList } from '@revelotech/everest'

import { createNamespacedHelpers } from 'vuex'
import { STAGES } from '@/store/namespaces'
import EvButton from '@revelotech/everestV2/EvButton'

const stagesHelper = createNamespacedHelpers(STAGES)

export default {
  name: 'ApplicantProposalForm',
  components: {
    ApplicantProposalBenefits,
    DatePickerDialog,
    EvButton
  },
  props: {
    applicant: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      proposalForm: {
        jobStartDate: '',
        jobSalary: '',
        benefits: [],
        message: ''
      }
    }
  },
  computed: {
    candidate () { return this.applicant?.candidate },
    candidateName () { return this.candidate?.name }
  },
  methods: {
    ...stagesHelper.mapActions([
      'createApplicantProposal',
      'moveApplicantOffer'
    ]),
    async handleSubmit () {
      if (!(await this.$validator.validate())) return

      try {
        await this.createApplicantProposal({
          applicantId: this.$route.params.applicantId,
          proposalParams: {
            ...this.proposalForm,
            candidateId: this.applicant?.candidate.id,
            jobSalary: moneyHelper.toNumber(this.proposalForm.jobSalary),
            positionId: this.$route.params.positionId
          }
        })

        this.moveApplicantOffer(this.applicant.id)

        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.proposalSent`),
          modifier: 'success'
        })

        this.$emit('success', 'proposal')
      } catch {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.proposalNotSent`),
          modifier: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss">
.applicant-proposal-form {
  margin-top: $base*3;

  & > * {
    margin: 0;
  }

  &__description {
    @extend %bold;

    margin-top: 5*$base;
  }

  &__field {
    &__assistive-text {
      @extend %caption;

      color: $gray60;
      margin-left: 3*$base;
      margin-top: $base;
    }
  }
}
</style>
