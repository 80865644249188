<template>
  <app-grid class="applicant-proposal-formalize">
    <app-grid-col
      class="applicant-proposal-formalize__field"
      :md="12"
    >
      <label class="applicant-proposal-formalize__label">
        {{ $t(`${langPath}.startDate`) }}
      </label>
      <date-picker-dialog
        v-model="proposalForm.jobStartDate"
        data-test="proposal-formalize__start-date"
        class="proposal-formalize__start-date-input"
        name="job-start-date"
        :placeholder="$t(`${langPath}.datePlaceholder`)"
      />
    </app-grid-col>
    <app-grid-col
      class="applicant-proposal-formalize__field"
      :md="12"
    >
      <label class="applicant-proposal-formalize__label">
        {{ $t(`${langPath}.salary`) }}
      </label>
      <app-text-input
        v-model="proposalForm.jobSalary"
        v-validate="'money_required|money_max:100000'"
        data-test="proposal-formalize__salary"
        name="salary"
        money-mask
        :data-vv-as="$t(`${langPath}.salary`)"
        data-vv-validate-on="blur"
      />
    </app-grid-col>
    <app-grid-col
      class="applicant-proposal-formalize__field"
      :md="12"
    >
      <ev-button
        data-ga-id="confirmed_proposal_formalization"
        data-test="proposal-formalize__send-button"
        @click="handleSubmit"
      >
        {{ $t(`${langPath}.send`) }}
      </ev-button>
    </app-grid-col>
  </app-grid>
</template>

<script>
import DatePickerDialog from '@/components/DatePickerDialog'
import moneyHelper from '@/helpers/money'
import { TheNotificationCardList } from '@revelotech/everest'

import { createNamespacedHelpers } from 'vuex'
import { STAGES } from '@/store/namespaces'
import EvButton from '@revelotech/everestV2/EvButton'

const stagesHelper = createNamespacedHelpers(STAGES)

export default {
  name: 'ApplicantProposalFormalize',
  components: {
    DatePickerDialog,
    EvButton
  },
  props: {
    applicant: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      proposalForm: {
        jobStartDate: '',
        jobSalary: ''
      }
    }
  },
  methods: {
    ...stagesHelper.mapActions([
      'createApplicantProposal',
      'moveApplicantHired'
    ]),
    async handleSubmit () {
      if (!(await this.$validator.validate())) return

      try {
        await this.createApplicantProposal({
          applicantId: this.$route.params.applicantId,
          proposalParams: {
            ...this.proposalForm,
            candidateId: this.applicant?.candidate.id,
            jobSalary: moneyHelper.toNumber(this.proposalForm.jobSalary),
            positionId: this.$route.params.positionId,
            accepted: true,
            onlyFormalized: true
          }
        })

        this.moveApplicantHired(this.applicant.id)

        this.$emit('success', 'formalize')

        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.proposalSent`),
          modifier: 'success'
        })
      } catch {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.proposalNotSent`),
          modifier: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss">
.applicant-proposal-formalize {
  margin-top: $base*3;

  & > * {
    margin: 0;
  }

  &__label {
    @extend %bold;
  }

  &__field {
    &__assistive-text {
      @extend %caption;

      color: $gray60;
      margin-left: 3*$base;
      margin-top: $base;
    }
  }
}
</style>
