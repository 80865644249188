<template>
  <app-tags-selector
    open-scope
    data-test="app-benefits-input"
    class="app-benefits__input-group"
    :autocomplete-placeholder="placeholder"
    :item-name="tip"
    :name="name"
    :options="suggestedBenefits"
    :options-title="optionsTitle"
    :placeholder="placeholder"
    :search-function="getBenefits"
    :value="parsedValue"
    @input="handleChange"
  />
</template>

<script>
import autocomplete from '@/repository/companies-app/autocomplete'
import suggestions from '@/repository/companies-app/suggestions'

export default {
  name: 'ApplicantProposalBenefits',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      required: false,
      type: String,
      default: ''
    },
    optionsTitle: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    tip: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      suggestedBenefits: []
    }
  },
  computed: {
    parsedValue () {
      return this.value.map(item => ({ value: item, label: item }))
    }
  },
  async created () {
    this.suggestedBenefits = (await suggestions.getBenefits()).map(
      (benefit) => ({ value: benefit.text, label: benefit.text })
    )
  },
  methods: {
    async getBenefits (name) {
      const benefits = await autocomplete.getBenefits(name)

      return benefits.map((benefit) => ({
        value: benefit.text,
        label: benefit.text
      }))
    },
    handleChange (value) {
      this.$emit('input', value.map(benefit => benefit.label))
    }
  }
}
</script>
